import { initializeApp } from 'firebase/app'
import { getAuth, OAuthProvider } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const app = initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  appId: process.env.REACT_APP_ID,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET
})

export const auth = getAuth(app)
export const firestore = getFirestore(app)

export const microsoftProvider = new OAuthProvider('microsoft.com')
microsoftProvider.setCustomParameters({ tenant: process.env.REACT_APP_TENANT })
