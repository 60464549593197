/**
 * @param {number} kilobytes
 * @param {string} to
 * @returns
 */
export const kilobyteConverter = (kilobytes, to = 'MB') => {
  const units = {
    MB: 1000,
    GB: 10000
  }
  return kilobytes / units[to]
}
