import { AlertMessage } from '@keoworld/gbl-ui-kit'
import { createContext, useState } from 'react'
import styled from 'styled-components'

const initialContext = {
  alert: { title: '', label: '', type: '' },
  setAlert: ({ title, label, type }) => {}
}

export const AlertContext = createContext(initialContext)

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(initialContext)

  return (
    <AlertContext.Provider value={{ alert, setAlert }}>
      {children}
      {alert?.type && (
        <AlertStyled
          title={alert.title}
          label={alert.label}
          type={alert.type}
          onClose={() => setAlert(undefined)}
        />
      )}
    </AlertContext.Provider>
  )
}
const AlertStyled = styled(AlertMessage)`
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 10;
`
