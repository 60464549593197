import { Buffer } from 'buffer'

/**
 * function to translate a json web token to a json object
 * @param {string} jwt - Json Web Token
 * @returns - JSON object
 */
export const jwtToJson = (jwt) => {
  const jwtParts = jwt.split('.')

  const header = Buffer.from(jwtParts[0], 'base64').toString('utf8')
  const payload = Buffer.from(jwtParts[1], 'base64').toString('utf8')

  return {
    header: JSON.parse(header),
    payload: JSON.parse(payload)
  }
}
